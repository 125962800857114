import Bets from '@/components/pages-partials/bets'
import { HANDCASH_WALLET, SERVER_ENDPOINT } from '@/constants/environment'
import { updateCurrentWalletType } from '@/firebase/utils'
import authSelector from '@/redux/selectors/auth'
import betSlipsSelector from '@/redux/selectors/betSlips'
import { setUserData } from '@/redux/slices/auth'
import { errorMsg, successMsg } from '@/utils/toast'
import { Transition } from '@headlessui/react'
import axios from 'axios'
import { useRouter } from 'next/router'
import React, { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import RightSidebar from '../RightSidebar'
import SharedLayout from '../shared-layout'

const RightSidebarLayout = ({
  title,
  children,
  showIcons,
  footer = footer,
  sidebar = true,
  className = '',
  sportsidebar,
  footballIcon,
  isHome,
}) => {
  const { slips, betslipsOpen, betslipsOpenMobile } =
    useSelector(betSlipsSelector)
  const { user } = useSelector(authSelector)
  const dispatch = useDispatch()
  const sm = useMediaQuery({ query: '(max-width: 639px)' })
  const router = useRouter()
  const [showRightSidebar, setShowRightSidebar] = useState(false)

  const calculateShowRightSidebar = () => {
    if (isHome === true && user?.roleType === 'admin') {
      return false
    } else if (isHome === true) {
      if (sm) {
        return betslipsOpenMobile
      } else {
        return slips?.length > 0
      }
    } else if (user?.roleType === 'admin') {
      return false
    } else {
      if (sm) {
        return betslipsOpenMobile
      } else {
        return betslipsOpen
      }
    }
  }

  useEffect(() => {
    const newShowRightSidebar = calculateShowRightSidebar()
    if (newShowRightSidebar !== showRightSidebar) {
      setShowRightSidebar(newShowRightSidebar)
    }
  }, [isHome, user, sm, slips, betslipsOpen, betslipsOpenMobile])

  const registerHandcash = async (authToken, user) => {
    const registerRes = await axios
      .post(SERVER_ENDPOINT + 'handcash/register', {
        authToken,
        uid: user.uid,
      })
      .then((res) => res.data)
      .catch((err) => err?.response?.data)

    if (registerRes?.status === 'success') {
      updateCurrentWalletType(user.uid, HANDCASH_WALLET).then(() => {
        dispatch(setUserData({ ...user, activeWallet: HANDCASH_WALLET }))
        router.replace('/home')
        successMsg('HandCash activated.')
      })
    } else {
      errorMsg(
        'Connection could not be established. Your Paymail address was not found.',
      )
    }
  }

  useEffect(() => {
    if (router?.query?.authToken && user) {
      registerHandcash(router?.query?.authToken, user)
    }
  }, [router?.query])

  return (
    <SharedLayout
      sidebar={sidebar}
      showRightSidebar={showRightSidebar}
      footer={footer}
      title={title}
      sportsidebar={sportsidebar}
      footballIcon={footballIcon}
      showIcons={showIcons}
      isHome
    >
      <div
        className={`min-h-full ${
          showRightSidebar ? 'lg:w-[calc(100%-20rem)]' : 'lg:w-[100%]'
        }`}
      >
        {children}

        <Transition
          show={showRightSidebar}
          enter="transition-transform duration-700 sm:transition ease-out duration-300"
          enterFrom="transform translate-x-full"
          enterTo="transform translate-x-0"
          leave="transition ease-in duration-300"
          leaveFrom="transform translate-x-0"
          leaveTo="transform translate-x-full"
          className={`fixed right-0 z-20  ${
            sm
              ? 'top-0 inset-0 backdrop-blur-md bg-black/90 '
              : 'sidebar top-24 '
          }  `}
        >
          <RightSidebar />
        </Transition>
        <Transition
          show={!showRightSidebar}
          enter="transition ease-out duration-300"
          enterFrom="transform translate-x-full"
          enterTo="transform translate-x-0"
          leave="transition ease-in duration-300"
          leaveFrom="transform translate-x-0 "
          leaveTo="transform translate-x-full"
        >
          <div className="block w-4">&nbsp;</div>
        </Transition>
      </div>
      {!isHome && (
        <Bets
          showRightSidebar={showRightSidebar}
          asComponent={true}
          isHomeLayout={true}
        />
      )}
    </SharedLayout>
  )
}

export default memo(RightSidebarLayout)
